.admin-page1{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    gap: 30px;
    /* background-image: url(../src/assets/adminpage.jpg); */
    top: 0;
    position: absolute;
    width: 100%;
    padding: 20px;
    
}
.nav{
    width: 100%;
    background-color: azure;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
}
.form-panel{
    /* style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr", gap:"10px"}}*/
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    background-color: white;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 20px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 850px) {
    .form-panel{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 630px) {
    .form-panel{
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
    }
    
}

.forms{
    /* style={{display:"flex", justifyContent:"center",alignItems:"center", flexDirection:"column", gap:"10px"}} */
    display: flex;
    justify-content: left;
    align-items: self-start;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    

}
.form1{
    display: flex;
    justify-content:end;
    align-items: flex-end;
    flex-direction: column;
}

.inputs{
    padding: 5px 5px 5px 5px;
    border: 1px solid #0a0a7e;
    outline: #0a0a7e;
    border-radius: 10px;
    width: 100%;
    
}
.inputs1{
    width: 100%;
}
.add-btn{
    background-color: #0a0a7e;
    color: #ffffff;
    padding: 7px 20px 7px 20px;
    border-radius: 20px;
}
.submit-btn{
    background-color: #0a0a7e;
    color: #ffffff;
    border-radius: 20px;
    width: 150px;
    height: 40px;
    margin-bottom: 20px;
}
/* -----------------------------Category-----------------------*/
.delete-btn{
    background-color: #db0606;
    color: #ffffff;
    border-radius: 20px;
    width: 150px;
    height: 40px;
    margin-bottom: 20px;
}
.confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .confirmation-modal p {
    margin-bottom: 10px;
  }
  
  .confirmation-modal button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .confirmation-modal button:hover {
    background-color: #f0f0f0;
  }
.image-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 20px;
    width: 100%;
}

@media (max-width: 850px) {
    .image-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 550px) {
    .image-container{
        display: grid;
        grid-template-columns: 1fr;
    }
}
.img-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    gap: 20px;
    margin-top: 10px;
    width: 100%;
    border-radius: 20px;
    padding-top: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.img-content img{
    width: 80%;
    height: 200px;
}
.img-content h6{
    width: 80%;
    height: 40px;
}





/* -----------------------------portfolioMain-----------------------*/

.project-section{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;
}

@media (max-width: 1000px) {
    .project-section{
        display: grid;
        grid-template-columns: 1fr 1fr ;
    }
}
@media (max-width: 600px) {
    .project-section{
        display: grid;
        grid-template-columns: 1fr;
    }
}

.data-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.para-btn{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: 130px;
    gap: 20px;
}
.image-pro{
    width: 90%;
    height: 250px;
    border-radius: 10px;
    margin-top: 20px;
}
.btn-pro{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    width: 100%;
    color: #0a0a7e;
}
.arrow{
    width: 25px;
    height: 25px;
    
}
.para-btn:hover .arrow {
    width: 60px;
    height: 25px;
}
.para-pro{
    font-size: 1.2rem;
    font-weight: 600;
    color: #3f3f3f;

}

/* -----------------------------deletePopup-----------------------*/

.Main-menu{
  width: 50px;
  height: 50px;
  position: relative;
}

.Side-Bar{
    height: 100vh;
    position: absolute;
    border-radius: 0px 20px 20px 0px;
    width: 300px;
    background-color: #0a0a7e;
    top: 0;
  }
  
  .cancel-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 10px;
    padding-top: 10px;
  }

  .cancel-btn {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 3px;
    background-color: white;
    height: 30px;
    border-radius: 20px;
  }

  .btns{
    color: #1d1d1d;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 20px;
    
  }
  .btn{
    display: flex;
    color: #1d1d1d;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 10px;

  }


/* -----------------------------sign In-----------------------*/

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
.sign-in-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  /* Heading */
  .sign-in-container h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  /* Error message */
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  /* Form */
  .sign-in-form {
    display: flex;
    flex-direction: column;
  }
  
  /* Form field */
  .sign-in-form input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
  }
  
  /* Submit button */
  .sign-in-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .sign-in-form button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive */
  @media (max-width: 480px) {
    .sign-in-container {
      width: 90%;
    }
  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: green;
    font-size: 2rem;
    font-weight: 700;
  }
  .btn-done{
    padding: 10px 20px 10px 20px;
    background-color: #019549;
    color: white;
    border-radius: 10px;
  
  }
  .TabList {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .Tab {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .Tab:last-child {
    margin-right: 0;
  }
  .img-content1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    gap: 20px;
    margin-top: 10px;
    width: 100%;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .img-content1 h6{
    width: 80%;
  
  }