.main-details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap:  20px;
}
.swiper{
    width: 100%
}

@media (max-width: 1280px) {
    .main-details{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        
    }
    .header{
        font-size: 1.5rem;
    }
}

@media (max-width: 700px) {
    .main-details{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .header{
        font-size: 1rem;
    }
}
@media (max-width: 430px) {
    .main-details{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .header{
        font-size: 1rem;
        font-weight: 400;
    }
}
@media (max-width: 380px) {
    .main-details{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .header{
        font-size: 1rem;
        font-weight: 400;
    }
}
.main-image{
    width: 100%;
    height: 400px;
}
.heading-main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.header{
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 40px;
}
.main-head{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 30px;

}
.main-sub-head{
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 20px;
}
.main-sub-des{
    margin-top: 20px;
    height: auto;
    border: none;
    resize: vertical;
     
}